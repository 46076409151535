import React from "react"
import Navigation from "./navigation"
import Footer from "./footer"
import containerStyles from "./blog-container.module.css"

export default({children}) => (
    <div>
      <Navigation/>
      <div className={containerStyles.blogContainer}>
          {children}
      </div>
      <Footer />
    </div>
)